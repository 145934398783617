import { useCallback, useEffect, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  useApiCall,
  usePreferencesContext,
  useRootContext,
} from '@jarvis/react-portal-addons';
import useProgramInfosApiCall from './useProgramInfosApiCall';
import useGrantsCall from './useGrantsCall';
import contentStack, {
  getEnvironmentSectionTitles,
} from '../services/contentStack';

const hpPlusAnalyticsId = 'HP+';
const hpSolutionsAnalyticsId = 'HP Solutions';
const printerTransferAnalyticsId = 'Transferring Printer from a Personal HP Account';

const removeSubMenuItem = (menuItem, analyticsIdValue) => {
  // Using analyticsID as a workaround - we will change to use subsection slugs when they are available.
  const subMenuIndex = menuItem?.appHelpSection.findIndex(subItem => {
    const subItemAnalyticsId = subItem.analyticsID
      || subItem.analyticsId
      || subItem.helpSection?.analyticsId;
    return subItemAnalyticsId === analyticsIdValue;
  });

  if (subMenuIndex >= 0) {
    menuItem.appHelpSection.splice(subMenuIndex, 1);
  }
};

const useHelpCenterApiCall = ({ accountId, authProvider, stack }) => {
  const [data, setData] = useState(null);
  const { localization } = useRootContext();
  const preferences = usePreferencesContext();
  const { locale } = localization;
  const { helpCenterTransferPrinter = false, grantsSupport = false } = useFlags();

  const getHelpCenterDataFetch = useCallback(async () => {
    try {
      return await contentStack({ locale, stack, preferences });
    } catch (error) {
      if (error?.response?.status === 422) {
        return contentStack({ locale: 'en-us', stack });
      }
      throw error;
    }
  }, [locale, preferences, stack]);

  const getSectionTitlesFetch = useCallback(async () => {
    try {
      return await getEnvironmentSectionTitles({ locale, preferences });
    } catch (error) {
      if (error?.response?.status === 422) {
        return getEnvironmentSectionTitles({ locale: 'en-us', preferences });
      }
      throw error;
    }
  }, [locale, preferences]);

  const helpCenterCall = useApiCall({
    accountId,
    authProvider,
    fetch: getHelpCenterDataFetch,
    initialData: null,
    initialFetch: true,
  });

  const sectionTitlesCall = useApiCall({
    accountId,
    authProvider,
    fetch: getSectionTitlesFetch,
    initialData: null,
    initialFetch:
      !!preferences.dataSource?.contentStack?.environmentSectionTitles?.fetch,
  });

  const forceFetch = () => helpCenterCall.forceFetch();
  const programInfosCall = useProgramInfosApiCall({
    accountId,
    authProvider,
    stack,
    init: !grantsSupport,
  });
  const grantsCall = useGrantsCall({
    accountId,
    authProvider,
    stack,
    init: grantsSupport,
  });
  const {
    hasInstantInk,
    hasPrintAnywhere,
    hasPrintPlans,
    hasSustainability,
    hasSmartAdvance,
    hasSmartPro,
    isHpPlus,
    isUcde,
  } = grantsSupport ? grantsCall : programInfosCall;

  const sortEntries = entries => entries.sort((a, b) => {
    if (a.index === b.index) {
      return 0;
    }
    if (a.index > b.index) {
      return 1;
    }
    return -1;
  });

  const filterByCustomMenuFilters = useCallback(
    (responseHelpCenterData, customMenuFilters) => responseHelpCenterData.filter(section => {
      const { slug = '' } = section;

      switch (slug) {
        case customMenuFilters.hpPlus?.slug:
          return isHpPlus;
        case customMenuFilters.instantInk_printPlans?.slug:
          return hasInstantInk || hasPrintPlans;
        case customMenuFilters.smartAdvance?.slug:
          return hasSmartAdvance;
        default:
          return true;
      }
    }),
    [isHpPlus, hasInstantInk, hasPrintPlans, hasSmartAdvance],
  );

  const filterByDefault = useCallback(
    entries => {
      let responseHelpCenterData = entries;
      if (!hasPrintPlans) {
        responseHelpCenterData = responseHelpCenterData.filter(
          item => item.slug !== 'hp-instant-ink',
        );
      }

      if (!hasSustainability) {
        responseHelpCenterData = responseHelpCenterData.filter(
          item => item.slug !== 'sustainability',
        );
      }

      if (!hasPrintAnywhere) {
        const printingItem = responseHelpCenterData.find(
          item => item.slug === 'printing',
        );
        removeSubMenuItem(printingItem, 'Remote Printing');
      }

      if (!isHpPlus && !hasSmartPro) {
        responseHelpCenterData = responseHelpCenterData.filter(
          item => item.slug !== 'solutions',
        );
      } else {
        const solutionsItem = responseHelpCenterData.find(
          item => item.slug === 'solutions',
        );

        if (!isHpPlus) {
          removeSubMenuItem(solutionsItem, hpPlusAnalyticsId);
        }

        if (!isUcde) {
          removeSubMenuItem(solutionsItem, hpSolutionsAnalyticsId);
        }
      }

      if (!helpCenterTransferPrinter) {
        const managingPrinters = responseHelpCenterData.find(
          item => item.slug === 'managing-printers-in-hp-smart-admin',
        );
        removeSubMenuItem(managingPrinters, printerTransferAnalyticsId);
      }
      return responseHelpCenterData;
    },
    [
      hasPrintPlans,
      hasSustainability,
      hasPrintAnywhere,
      isHpPlus,
      hasSmartPro,
      isUcde,
      helpCenterTransferPrinter,
    ],
  );

  useEffect(() => {
    if (!helpCenterCall.data) {
      return;
    }

    let entries = JSON.parse(JSON.stringify(helpCenterCall.data.entries));
    entries = sortEntries(entries);

    let responseHelpCenterData = entries;

    if (Object.keys(preferences.customMenuFilters).length > 0) {
      responseHelpCenterData = filterByCustomMenuFilters(
        responseHelpCenterData,
        preferences.customMenuFilters,
      );
    } else {
      responseHelpCenterData = filterByDefault(responseHelpCenterData);
    }

    setData(responseHelpCenterData);
  }, [
    filterByCustomMenuFilters,
    filterByDefault,
    helpCenterTransferPrinter,
    hasInstantInk,
    hasPrintAnywhere,
    hasSmartAdvance,
    hasSmartPro,
    hasSustainability,
    helpCenterCall.data,
    isHpPlus,
    isUcde,
    preferences,
    hasPrintPlans,
  ]);

  return {
    data,
    error:
      helpCenterCall.error || programInfosCall.error || sectionTitlesCall.error,
    forceFetch,
    isFetching:
      helpCenterCall.isFetching
      || programInfosCall.isFetching
      || sectionTitlesCall.isFetching,
    programInfosCall,
    sectionsTitles: sectionTitlesCall.data,
  };
};

export default useHelpCenterApiCall;
